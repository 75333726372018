<!-- @Author: Yu_Bo -->
<template>
	<div class='member_management'>
    <!-- 标题 -->
    <div class="member_title"><span></span>成员管理</div>
    <!-- 搜索 -->
    <div class="member_top">
      <!-- <el-button class="btnBgColor_blue" size="small" type="primary" icon="el-icon-plus"
      	@click="addEditBtn(1,'')">新增</el-button> -->
      <div class="input">
        <el-input placeholder="请输入账号" size="small" prefix-icon="el-icon-search" v-model="params.account"
        	@keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true">
        </el-input>
      </div>
      <div class="select">
        <div class="name">所属组织</div>
        <div class="right">
          <el-select v-model="params.organization" @change='searchBtn' :clearable='true' placeholder="请选择" size="small">
            <el-option
              v-for="item in organizationList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="table_wrap">
      <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
        <template slot="empty">
          <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
        </template>
        <el-table-column prop="username" label="账号">
        </el-table-column>
        <el-table-column prop="memberRole.role_name" label="角色">
        </el-table-column>
        <el-table-column prop="memberOrganization.name" label="组织">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
        </el-table-column>
        <el-table-column prop="nickname" label="名称">
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间">
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间">
        </el-table-column>
        <el-table-column prop="login_time" label="最后登录">
        </el-table-column>
        <el-table-column prop="switch" label="是否启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addEditBtn(2,scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_box" v-if="total">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 温馨提示 -->
    <point-out ref='dialogTip' dialogTitle="提示" type="cyDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">删除该账号后不能再登录，确定要删除该成员吗？</div>
      </div>
    </point-out>
    <!-- 账号管理 -->
    <account-manage ref="dialogAccount" :orglist='organizationList' :rolelist='roleList' :type="account_type" @submit="searchBtn"></account-manage>
	</div>
</template>

<script>
  import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
  import PagingPage from '@/components/paging_page/paging_page.vue'//分页
  import AccountManage from '../components/account_manage/account_manage.vue'//账号管理
	export default {
		components: {PointOut, EmptyData, PagingPage, AccountManage },
		props: {},
		data() {
			return{
        id:'',
        params:{
          account:'',
          organization:''
        },
        // 组织
        organizationList:[],
        // 角色
        roleList:[],
        //
        loading:false,
        list:[],
        page: 1,
        limit: 10,
        total: 0,
        //
        del_index:'',
        //
        account_type:1,
			}
		},
		computed: {},
		watch: {},
		created() {
      if(this.$route.params.id){
        this.id=this.$route.params.id
        this.getList()
        var name = this.$route.query.name + '-成员管理'
        var key = this.$route.meta.key + '_' + this.$route.params.id
        this.$store.dispatch('tabsArr/getName',{name,key})
      }
      // 获取组织/角色列表
      this.listData()
    },
		mounted() {},
		methods: {
      // 获取组织/角色列表
      listData(){
        var that = this
        that.$personApi.corpRel().then(res => {
          if (res.code == 1000) {
            // 组织
            that.organizationList=res.result.organization_tree_dic
            // 角色
            that.roleList=res.result.role_dic
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 搜索
      searchBtn(){
        this.page = 1
        this.list = []
        this.total = 0
        this.getList()
      },
      getList() {
        var that = this
        that.loading=true
        var params = {
          id:that.id,
          page: that.page,
          limit: that.limit,
          keyword:that.params.account,
          member_org_id:that.params.organization,
        }
        that.$personApi.memberList(params).then(res => {
          that.loading = false
          if (res.code == 1000) {
            that.list=res.result.data
            that.total=res.result.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
      // 修改状态
      changeSwitch(val,info,index){
        var that = this
        var params = {
          id: info.id,
          status: val,
        }
        that.$personApi.corpRelated(params).then(res => {
          if (res.code == 1000) {
            that.$succMsg(res.message)
            that.list[index].status=val
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 删除
      delBtn(val){
        this.del_index=val
        this.$refs.dialogTip.openDialogBtn()
      },
      //确定删除
      closeListBtn(){
        var that = this
        if(that.del_index!==''){
          that.$personApi.delCorpRelated('',that.list[that.del_index].id).then(res => {
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.getList()
              // that.list.splice(this.del_index,1)
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 新增/编辑
      addEditBtn(type,info){
        this.account_type=type
        this.$refs.dialogAccount.openDialogBtn(this.id,info)
      },
    },
	}
</script>

<style lang='scss' scoped>
  @import '../index.scss';
	.member_management{
    width: 100%;
    padding: 20px;
    background: rgba(255,255,255,0.47);
    border-radius: 10px;
    .member_title{
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      span{
        display: block;
        width: 5px;
        height: 18px;
        margin-right: 15px;
        background: #2E4BF2;
        border-radius: 3px;
      }
    }
    .member_top{
      width: 100%;
      margin-top: 30px;
      display: flex;
      align-items: center;
      .input{
        width: 186px;
        /* margin-left: 30px; */
        .el-input{
          width: 100%;
          ::v-deep .el-input__inner{
            border: none;
          }
        }
      }
      .select{
        margin-left: 30px;
        display: flex;
        align-items: center;
        .name{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .right{
          width: 160px;
          margin-left: 12px;
          .el-select{
            width: 100%;
            ::v-deep .el-input__inner{
              border: none;
            }
          }
        }
      }
    }
    .table_wrap{
      margin-top: 20px;
    }
	}
  .table_wrap .el-table{
    width: 100%;
    border-radius: 2px;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
  }
  ::v-deep .last_class{
    border-left:1px solid #EBEEF5;
  }
</style>
