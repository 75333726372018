<!-- @Author: Yu_Bo -->
<template>
  <div class='account_manage zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="534px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">{{ title }}</div>
      </div>
      <div class="inv_main">
        <div class="main_text">
          <div class="left">名称</div>
          <div class="right">
            <el-input v-model="name" placeholder="请输入名称" maxlength="20" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>手机号</div>
          <div class="right">
            <el-input :disabled='type == 1 ? false : true' v-model="phone" placeholder="请输入手机号" maxlength="11"
              show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>组织</div>
          <div class="right">
            <!-- <el-select v-model="organizValue" :disabled='type==1?false:true' placeholder="请选择">
              <el-option
                v-for="item in orglist"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
            <el-cascader
              v-model="organizValue"
              :disabled='type == 1 ? false : true'
              :show-all-levels="false"
              placeholder="请选择"
              :options="orglist"
              :props="{ checkStrictly: true }"
              clearable></el-cascader>
          </div>
        </div>

        <div class="main_text">
          <div class="left"><span>*</span>角色</div>
          <div class="right">
            <el-select v-model="roleValue" placeholder="请选择">
              <el-option v-for="item in rolelist" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding: 0 30px;">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: {
      type: Number,
      defaul: 1
    },
    orglist:{
      type: Array,
      defaul: []
    },
    rolelist:{
      type: Array,
      defaul: []
    }
  },
  data() {
    return {
      dialogVisible: false,
      id:'',
      name: '',
      phone: '',
      // 组织
      organizValue: [],
      // 角色
      roleValue: '',
      //
      btnLoading:false,
    }
  },
  computed: {
    title() {
      return this.type == 1 ? '新增' : '编辑'
    },
  },
  watch: {},
  created() {

  },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn(vid,info) {
      var that = this
      this.dialogVisible = true
      this.$nextTick(function(){
        if(vid){
            this.roleValue=parseInt(vid)
        }
        if(info){
            this.id=info.id
            this.name=info.nickname
            this.phone=info.mobile
            this.roleValue=info.member_role_id
            that.orgData(that.orglist,info.member_org_id)
        }
      })
    },
    orgData(arr,vid){
      var str = []
      arr.forEach(item=>{
        if(item.value==vid){
          if(item.parent_id){
            str.push(item.parent_id+'')
            str.push(item.value)
            this.organizValue=str
          }else{
            str.push(item.value)
            this.organizValue=str
          }
        }else{
          if(item.children && item.children.length){
            this.orgData(item.children,vid)
          }
        }
      })
    },
    // 取消
    cancelBtn() {
      this.name=''
      this.phone=''
      this.organizValue=[]
      this.roleValue=''
      this.dialogVisible = false
    },
    // 确定
    affirmBtn() {
      var that = this
      if(that.phone=='' || !this.$testVerify.isvalidPhone(that.phone)){
        this.$errMsg('请输入正确手机号')
        return
      }else if(that.organizValue.length==0){
        this.$errMsg('请选择组织')
        return
      }else if(that.roleValue==''){
        this.$errMsg('请选择角色')
        return
      }else if(that.type==1){
        that.btnLoading=true
        var params = {
          nickname:that.name,
          mobile:that.phone,
          member_org_id:that.organizValue[that.organizValue.length-1],
          member_role_id:that.roleValue,
        }
        that.$personApi.postCorpRelated(params).then(res => {
          that.btnLoading=false
          if (res.code == 1000) {
            that.cancelBtn()
            that.$succMsg(res.message)
            that.$emit('submit')
          } else {
            that.$errMsg(res.message)
          }
        })
      }else if(that.type==2){
        that.btnLoading=true
        var params = {
          nickname:that.name,
          member_role_id:that.roleValue,
        }
        that.$personApi.putCorpRelated(params,that.id).then(res => {
          that.btnLoading=false
          if (res.code == 1000) {
            that.cancelBtn()
            that.$succMsg(res.message)
            that.$emit('submit')
          } else {
            that.$errMsg(res.message)
          }
        })
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.account_manage {
  .inv_main {
    width: 100%;
    padding: 10px 30px 0;

    .main_text {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .left {
        width: 60px;
        line-height: 40px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        span {
          padding-right: 5px;
          color: #E00C25;
        }
      }

      .right {
        width: 360px;

        .el-input {
          width: 100%;
        }

        .el-select {
          width: 100%;
        }
        .el-cascader{
          width: 100%;

        }
      }
    }

    .main_text:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
